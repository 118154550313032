<p-confirmDialog styleClass="md:w-4 w-full"></p-confirmDialog>

<p-menu #chatContextMenu [model]="contextMenu" [popup]="true">
  <ng-template pTemplate="item" let-item>
    @if (item.visible !== false) {
    <li
      [routerLink]="item.routerLink || undefined"
      (click)="(item.command || undefined)"
      [class]="item.classList"
      [ngClass]="{ 'p-disabled': item.disabled || false }"
      class="p-2 align-items-center gap-2 cursor-pointer"
    >
      <custom-icon [type]="'material'" [iconName]="item.icon"></custom-icon>
      <p>{{ item.label | translate }}</p>
    </li>
    }
  </ng-template>
</p-menu>

<div class="chat-topbar">
  <div class="left-section w-full layout-breadcrumb">
    <ol>
      <li class="title cursor-pointer text-accent" [routerLink]="['/', 'private', 'assistants-list']">
        {{ 'private.assistantPage.topbar.interact' | translate }}
      </li>
      <li class="layout-breadcrumb-chevron">
        <custom-icon type="material" iconName="chevron_right"></custom-icon>
      </li>
      <li class="subtitle">{{ bot?.name }}</li>
      <li class="layout-breadcrumb-chevron">
        <custom-icon type="material" iconName="chevron_right"></custom-icon>
      </li>
      @if (conversation?.id || conversationTitle || chatTopbarService.conversationTitle) {
      <p-inplace [active]="isEditing" (onActivate)="onActivate()" (onDeactivate)="toggleEdit()" styleClass="conversation-inplace">
        <ng-template pTemplate="display">
          <li class="flex align-items-center subtitle" [ngClass]="{ 'conversation-title': conversation?.title }">
            {{
              conversation?.title || chatTopbarService.conversationTitle || conversationTitle
                | smartTruncate : 40 || ('private.assistantPage.topbar.newChat' | translate)
            }}
            <i class="pi pi-pencil text-sm pl-2 edit-icon cursor-pointer"></i>
          </li>
        </ng-template>
        <ng-template pTemplate="content">
          <input
            type="text"
            pInputText
            [(ngModel)]="conversationTitle"
            (keyup.enter)="editConversationTitle()"
            (blur)="toggleEdit()"
            [style]="{ width: 'max(' + (conversationTitle.length || 20) + 'ch, 200px)' }"
            [attr.size]="conversationTitle.length || 20"
            #titleInput
          />
        </ng-template>
      </p-inplace>
      } @else {
      <li class="flex align-items-baseline subtitle gap-2">
        {{ 'private.assistantPage.topbar.newChat' | translate }}
        @if (isGettingConversation) {
        <i class="pi pi-spinner pi-spin flex w-fit"></i>
        }
      </li>
      }
    </ol>
  </div>
  <div class="right-section flex flex-row gap-2">
    @if (conversation) {
    <div class="flex align-items-center">
      <span class="rating" #ratingContainer>
        <p-rating
          #rating
          class="p-rating-custom"
          [cancel]="false"
          [(ngModel)]="conversationReaction.rating"
          (onRate)="openCommentPanel($event.originalEvent, ratingContainer)"
        ></p-rating>
      </span>
      <p-overlayPanel #commentPanel [appendTo]="'body'" [dismissable]="false" [showCloseIcon]="false" [style]="{ 'min-width': '33%' }">
        <ng-template pTemplate="content">
          <div class="flex flex-column gap-4">
            <textarea
              pInputTextarea
              [(ngModel)]="conversationReaction.comment"
              [placeholder]="'private.assistantPage.topbar.commentPlaceholder' | translate"
            ></textarea>
            <div class="flex gap-2 justify-content-end">
              <button
                pButton
                class="p-button-sm p-button-danger p-button-outlined"
                (click)="onCancelRating()"
                [label]="'common.cancel' | translate"
              ></button>
              <button
                pButton
                [loading]="isRating"
                class="p-button-sm p-button-success"
                (click)="saveReaction()"
                [label]="'private.assistantPage.topbar.rate' | translate"
              ></button>
            </div>
          </div>
        </ng-template>
      </p-overlayPanel>
    </div>
    <p-divider layout="vertical" styleClass="p-0"></p-divider>
    }
    <span class="flex flex-row gap-1">
      <span class="history" #historyContainer>
        <button pButton class="p-button-text p-button-rounded p-2 show-desktop" (click)="historyMenu.toggle($event)">
          <i class="pi pi-history w-fit text-lg"></i>
        </button>
        <p-overlayPanel
          #historyMenu
          [appendTo]="'body'"
          styleClass="history-panel"
          [style]="{ 'min-width': '50%', 'max-height': '70dvh' }"
          (onShow)="getHistory()"
        >
          @if (isLoadingHistory) {
          <div class="w-full flex justify-content-center align-items-center" style="height: 20rem">
            <p-progressSpinner></p-progressSpinner>
          </div>
          } @else if(!chatHistory.length) {
          <div class="w-full flex justify-content-center align-items-center" style="height: 20rem">
            <span class="text-lg">{{ 'private.assistantPage.topbar.noHistory' | translate }}</span>
          </div>
          } @else {
          <div class="flex flex-column w-full h-full overflow-y-scroll">
            <span class="flex align-items-center justify-content-between pt-3 pb-5">
              <p class="font-semibold my-0">{{ 'private.assistantPage.topbar.history' | translate | uppercase }}</p>
              <custom-icon [iconName]="'filter_alt'" class="cursor-pointer" (click)="goToHistory(true)"></custom-icon>
            </span>
            <div class="flex flex-column gap-2">
              @for (chat of chatHistory; track chat.id; let i = $index) {
              <div
                class="card history-card shadow-none border-1 mb-0 p-2"
                [ngClass]="{ 'bg-gray-100': chat.id === conversation?.id }"
                (click)="onHistoryClick(chat)"
              >
                <div class="flex align-items-center justify-content-between">
                  <span class="text-xs text-gray-400">{{ chat.createdAt! | datestringToDate : 'FF' }}</span>
                  <span class="flex align-items-center gap-2 icons-container">
                    <custom-icon [class]="'text-md'" [iconName]="'edit'" (click)="onEditClick($event, inplace, chat.title)"></custom-icon>
                    <custom-icon [class]="'text-md'" [iconName]="'share'" (click)="openShareModal($event, chat)"></custom-icon>
                    <custom-icon [class]="'text-md'" [iconName]="'delete'" (click)="deleteChat($event, chat)"></custom-icon>
                  </span>
                </div>
                <span class="w-9 text-lg font-semibold">
                  <p-inplace #inplace [preventClick]="true" styleClass="inplace" (onActivate)="onInplaceActivate(i)">
                    <ng-template pTemplate="display">
                      <p class="font-semibold py-2 my-0">{{ chat.title }}</p>
                    </ng-template>
                    <ng-template pTemplate="content">
                      <input
                        (click)="$event.stopPropagation()"
                        [id]="'input-inplace-' + i"
                        type="text"
                        class="my-2 w-full"
                        [(ngModel)]="tempNewChatName"
                        (keydown.enter)="editChatName($event, inplace, chat)"
                        (blur)="deactivateInplace($event, inplace)"
                        pInputText
                      />
                    </ng-template>
                  </p-inplace>
                </span>
                <p-rating
                  class="p-rating-custom pb-2"
                  [readonly]="true"
                  [cancel]="false"
                  [disabled]="true"
                  [ngModel]="chat.reaction.rating"
                ></p-rating>
              </div>
              }
            </div>
            <div class="flex justify-content-end pt-5 pb-3 bg-white sticky bottom-0">
              <div class="flex gap-2 align-items-center font-semibold cursor-pointer see-all-button" (click)="goToHistory()">
                <p class="my-0">
                  {{ 'private.assistantPage.topbar.goToHistoryPage' | translate }}
                </p>
                <i class="pi pi-arrow-right text-sm w-auto"></i>
              </div>
            </div>
          </div>
          }
        </p-overlayPanel>
      </span>
      <button
        pButton
        class="p-button-text p-button-rounded p-2 show-desktop"
        [disabled]="!conversationId || conversationId === 'new'"
        (click)="reloadChat()"
      >
        <i class="pi pi-plus w-fit text-lg"></i>
      </button>
      <button pButton class="p-button-text p-button-rounded p-2" (click)="chatContextMenu.toggle($event)">
        <i class="pi pi-ellipsis-h w-fit text-lg"></i>
      </button>
    </span>
  </div>
</div>
<p-divider styleClass="my-1 mx-auto custom-divider"></p-divider>
