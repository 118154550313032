import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChatTopbarService {
  conversationTitle: string = '';

  constructor() {}
}
