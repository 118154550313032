<div class="upper-topbar flex justify-content-between lg:p-0 p-3">
  <span class="flex align-items-center md:gap-3 gap-1 w-full">
    <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
      <i class="pi pi-bars"></i>
    </button>
    <span id="chat-topbar-container-mobile" class="flex-1">
      @if(user && isInChat) {
      <app-chat-topbar [isFromMobile]="true"></app-chat-topbar>
      } @else {
      <app-breadcrumb></app-breadcrumb>
      }
    </span>
  </span>
</div>
