import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'smartTruncate',
})
export class SmartTruncatePipe implements PipeTransform {
  transform(value: string | null, maxLength: number): string {
    if (value && value.length <= maxLength) {
      return value;
    }

    const halfLength = Math.floor((maxLength - 3) / 2);
    const startStr = value?.slice(0, halfLength);
    const endStr = value?.slice(-halfLength);
    const ellipsis = '...';
    if (startStr && endStr) {
      const truncated = `${startStr}${ellipsis}${endStr}`;
      return truncated.length > maxLength ? truncated.slice(0, maxLength) : truncated;
    } else {
      return '';
    }
  }
}
