import { Component } from '@angular/core';
import { UsersService } from 'src/app/main/services/users.service';
import { User } from '../../models/user.model';
import { UserVerified } from '../../enums/user-verified.enum';

@Component({
  selector: 'app-not-verified-warning',
  templateUrl: './not-verified-warning.component.html',
  styleUrl: './not-verified-warning.component.scss',
})
export class NotVerifiedWarningComponent {
  readonly UserVerified = UserVerified;
  user?: User;

  constructor(private userService: UsersService) {
    this.userService.getUser$().subscribe({
      next: (user) => {
        this.user = user;
      },
    });
  }
}
