import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from '../../../../core/layout/service/app.layout.service';
import { UsersService } from 'src/app/main/services/users.service';
import { User } from '../../models/user.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-main-topbar',
  templateUrl: './main-topbar.component.html',
})
export class MainTopbarComponent {
  @ViewChild('menubutton') menuButton!: ElementRef;
  user?: User;

  isInChat: boolean = false;

  constructor(private readonly location: Location, private readonly usersService: UsersService, private readonly layoutService: LayoutService) {
    this.usersService.getUser$().subscribe({
      next: (user) => {
        this.user = user;
      },
    });

    this.checkIfIsInChat(this.location.path());

    this.location.onUrlChange((url) => {
      this.checkIfIsInChat(url);
    });
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showProfileSidebar();
  }

  checkIfIsInChat(segment: string) {
    this.isInChat = segment.split('/').includes('chat');
  }
}
