import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { map, Subscription } from 'rxjs';
import { UsersService } from '../../services/users.service';
import { ORG_FEATURES_ACTIONS, ORG_FEATURES_SECTIONS } from '../models/user.model';

@Directive({
  selector: '[profileSettingAllowed]',
})
export class ProfileSettingAllowedDirective implements OnDestroy {
  private readonly user$ = this.userService.getUser$();
  private subscription?: Subscription;

  constructor(private readonly userService: UsersService, private readonly templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @Input() set profileSettingAllowed(key: ORG_FEATURES_SECTIONS | ORG_FEATURES_ACTIONS) {
    this.subscription = this.user$.pipe(map((user) => user?.orgSettings?.ORG_FEATURES?.sections?.[key as ORG_FEATURES_SECTIONS])).subscribe({
      next: (sections) => {
        this.viewContainer.clear();
        if (sections === undefined) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (sections === false) {
          this.viewContainer.clear();
        } else {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
